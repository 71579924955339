// Prefixes hours with a leading zero
function formatHour(value: string) {
  const parsedValue = parseInt(value, 10)

  if (parsedValue > 9) {
    return parsedValue
  }

  return `0${parsedValue}`
}

/**
Algorithm that generates a dictionary with time strings on the format:
{
  0: 16:00,
  1: 16:15,
  2: 16:30,
  3: 16:45,
  4: 17:00
}
*/
function generateIndexToTimeValues(start: number, step: number) {
  const modIndex = 60 / step
  const values: Record<number, string> = {}

  for (let i = 0; i < 24 * modIndex - (modIndex - 1); i++) {
    const isWholeHour = i % modIndex === 0

    if (isWholeHour) {
      const hour = (start + Math.floor(i / modIndex)) % 24
      values[i] = `${formatHour(hour.toString())}:00`
    } else {
      // @ts-expect-error: noUncheckedIndexedAccess
      values[i] = `${formatHour(values[i - 1].split(":")[0])}:${
        (i % modIndex) * step
      }`
    }
  }

  return values
}

const indexToTimeValues = generateIndexToTimeValues(16, 15)

function generateTimeToIndexValues() {
  return Object.keys(indexToTimeValues).reduce<Record<string, number>>(
    (acc, curr) => {
      // @ts-expect-error: noUncheckedIndexedAccess
      acc[indexToTimeValues[parseInt(curr, 10)]] = parseInt(curr, 10)
      return acc
    },
    {}
  )
}

const timeToIndexValues = generateTimeToIndexValues()

export function getTimeString(index: number): string {
  const val = indexToTimeValues[index]
  if (val === undefined) {
    throw new Error(`Invalid index number: ${index}`)
  }
  return val
}

export function getTimeIndex(time: string): number {
  const val = timeToIndexValues[time]
  if (val === undefined) {
    throw new Error(`Invalid time: ${time}`)
  }
  return val
}
