import { useState } from "react"
import styled from "styled-components"

import { Slider } from "@material-ui/core"

import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

const StyledSlider = styled(Slider)`
  max-width: 90%;
  display: block;
  margin: ${spacing.XL2} ${spacing.XL};
`

const WarningText = styled(MBanner)`
  text-align: start;
`

export function TimeSlider({
  value,
  min,
  max,
  unit,
  warning,
  setValue,
}: {
  value: number
  min: number
  max: number
  unit: string
  warning: string
  setValue: (n: number) => void
}) {
  const marks = [
    {
      value: min,
      label: `${min} ${unit}`,
    },
    {
      value: max,
      label: `${max} ${unit}`,
    },
  ]
  const [displayValue, setDisplayValue] = useState(value)
  const showWarning = warning && value < min

  return (
    <>
      <StyledSlider
        value={displayValue}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
        onChange={(e: any, newValue: any) => {
          setDisplayValue(newValue)
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
        onChangeCommitted={(e: any, val: number | number[]) =>
          setValue(val as number)
        }
        step={1}
        min={min}
        max={max}
        marks={marks}
        valueLabelDisplay="on"
      />
      {showWarning && (
        <WarningText type="error">
          We recommend using a minimum duration of 5 minutes, to avoid false
          alarms and fast battery drainage
        </WarningText>
      )}
    </>
  )
}
