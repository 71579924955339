import { useState } from "react"
import styled from "styled-components"

import { Slider } from "@material-ui/core"

import { getTimeString } from "src/components/DeviceSettingsDialog/NoiseThresholds/formatTimeRange"
import { translateSliderValue } from "src/components/Settings/utils"
import { spacing } from "src/ui/spacing"
import { formatTimeString } from "src/utils/l10n"

export function SliderTimeRange({
  value,
  setValue,
  clockType,
}: {
  value: string
  setValue: (s: string) => void
  clockType: "12" | "24"
}) {
  const [times, setTimes] = useState(translateSliderValue(value))
  const marks = [
    { value: 0, label: formatTimeString("16:00", clockType) },
    { value: 92, label: formatTimeString("15:00", clockType) },
  ]

  function handleChangeCommitted(newValue: number[]) {
    const lowerValue = newValue[0]
    const upperValue = newValue[1]
    // @ts-expect-error: noUncheckedIndexedAccess
    const s = `${getTimeString(lowerValue)}-${getTimeString(upperValue)}`
    setValue(s)
  }

  function timeRange() {
    const val0 = formatTimeString(getTimeString(times[0]), clockType)
    const val1 = formatTimeString(getTimeString(times[1]), clockType)
    return `${val0} - ${val1}`
  }

  return (
    <>
      <StyledSlider
        value={times}
        min={0}
        max={92}
        marks={marks}
        // @ts-expect-error: noUncheckedIndexedAccess
        onChange={(e: any, val: number | number[]) => setTimes(val as number[])} // eslint-disable-line @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
        onChangeCommitted={(e: any, val: number | number[]) =>
          handleChangeCommitted(val as number[])
        }
        valueLabelDisplay="off"
      />

      <CurrentSetting>
        Set to: <b>{timeRange()}</b>
      </CurrentSetting>
    </>
  )
}

const StyledSlider = styled(Slider)`
  max-width: 90%;
  display: block;
  margin: ${spacing.XL};
`

const CurrentSetting = styled.div`
  opacity: 0.8;
  text-align: right;
  font-size: 0.875rem;
`
